@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  p,
  span,
  a {
    font-family: "Montserrat", sans-serif;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "M PLUS Rounded 1c", sans-serif;
  }
}

@layer components {
  .hero-text-wrapper {
    @apply container flex items-center text-left z-auto bg-opacity-90 rounded-lg text-white mx-auto p-4 sm:p-8;
  }
  .hero-animals {
    @apply absolute bottom-[-41px] right-1/2 translate-x-1/2 w-[190px] sm:w-[250px] sm:bottom-[-57px] lg:w-[300px] lg:bottom-[-69px] lg:right-[25%];
  }
  .section {
    @apply w-full flex flex-col justify-center px-6 pt-16 lg:px-[50px];
  }
  .full-section {
    @apply container mx-auto px-5 md:px-0 flex flex-col py-20 lg:py-32;
  }
  .nav-link {
    @apply uppercase hover:bg-transparent;
  }
  .nav-link-active {
    @apply font-bold after:w-full after:h-[2px] after:absolute after:bottom-0 after:bg-primary after:left-1/2 after:-translate-x-1/2;
  }
}

html,
body {
  overflow-x: hidden !important;
}

.swiper {
  width: 100%;
  height: 100%;
  margin: auto;
}
@media screen and (min-width: 992px) {
  .swiper {
    width: 80% !important;
  }
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 100%;
  max-height: 700px !important;
}

.swiper-container .swiper-pagination-bullet {
  background: #ffffff !important;
}
@media screen and (min-width: 992px) {
  .swiper-container .swiper-pagination-bullet {
    width: 12px !important;
    height: 12px !important;
  }
}
.swiper-container .swiper-pagination-bullet-active {
  transform: scale(1.2) !important;
}

.animals-container .swiper-pagination-bullet {
  background: #ffffff !important;
}

.fidelity-wrapper {
  transform: scale(1) rotateY(-30deg) rotateX(45deg) translateZ(4.5rem);
  transform-origin: 50% 100%;
  transform-style: preserve-3d;
  box-shadow: 1rem 1rem 2rem rgba(0, 0, 0, 0.25);
  width: 80%;
  max-width: 400px;
  border-radius: 12px;
  background-color: transparent;
}

.fidelity-wrapper::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: 1rem 1rem 2rem rgba(0, 0, 0, 0.25);
  background-size: cover;
  transform: translateZ(-4rem);
  border-radius: 12px;
}

.fidelity-wrapper::before:hover {
  transform: translateZ(0);
}
